export const themeColors = {
  maincolor: {
    tertiary: "#3D8705",
  },
  text: {
    primary: "#17171A",
    secondary: "#626971",
    black: "#000000",
  },
  common: {
    surfaceGray: "#E5E8E8",
    white: "#FFFFFF",
    hover: "#326E04",
    secondary: "#626971",
    disabled: "#E5E8E8",
    borderWhite: "#E1DAD0",
    pillWhite: "#FAF9F8",
    brown: "#876C43",
    error: "#DD3232",
    earth: "#DCD2C6",
    earthDark: "#7A613F",
    grayDark: "#273444",
    gray: "#8492a6",
    grayLight: "#d3dce6",
    notification: "#12A244",
    note: "#D98600",
    chipBorderColor : "#E1DaD0"
  },
};
