import { Button as BaseButton, styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";
import { themeColors } from "../Constant/themeColor";

const ButtonRounded = styled(BaseButton)((props) => ({
  fontSize: "16px",
  textAlign: "center",
  cursor: "pointer",
  fontWeight: 400,
  fontFamily: themeFonts.regular,
  backgroundColor: themeColors.maincolor.tertiary,
  lineHeight: "16.2px",
  width: "50%",
  heigh: "48px",
  padding: "17px 24px 15px 24px",
  borderRadius: "30px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: themeColors.maincolor.tertiary,
  },
  "&:disabled": {
    backgroundColor: "#E5E8E8",
    color: "#626971",
    cursor: "none",
  },
  "&:enabled": {
    backgroundColor: themeColors.maincolor.tertiary,
    color: "white",
  },
}));

export const ButtonSquare = styled(ButtonRounded)(({ theme, color }) => ({
  borderRadius: "4px",
  width: "100%",
}));

export const ButtonSquareSecondary = styled(ButtonSquare)(
  ({ theme, color }) => ({
    "&.MuiButton-root": {
      background: "transparent",
      color: "#17171A",
      border: `1px solid ${themeColors.text.primary}`,
    },
  })
);

export default ButtonRounded;
