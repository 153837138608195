export const themeFonts = {
  extraBold: "nesxbd",
  semiBoldItalic: "nesSemiBd",
  extraBoldItalic: "nesxbditalic",
  mediumItalic: "nesmdi",
  semiBold: "nessemiBd",
  regular: "nesregular",
  medium: "nesMed",
  lightItalic: "neslightitalic",
  light: "neslight",
  boldItalic: "nesBoldItalic",
  bold: "nesBold",
  italic: "nesItalicNormal",
};
