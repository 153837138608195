import {
  Box,
  Container,
  InputLabel,
  Typography,
  Card,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import "../../assets/Css/mainOriginalList.css";
import "../../assets/Css/mainOriginalListRes.css";
import { themeFonts } from "../../Constant/themeFont";
import { themeColors } from "../../Constant/themeColor";

const ContinueCheckoutPage = () => {
  return (
    <>
      <Container>
        <Typography
          variant="h4"
          fontFamily={themeFonts.bold}
          fontSize={"24px"}
          lineHeight={"28px"}
          letterSpacing={"1px"}
          fontWeight={700}
          textTransform={"uppercase"}
          sx={{
            padding: "24px 0px 16px 0px",
            textAlign: "center",
          }}
        >
          Hai già ritirato delle capsule in unA POSTAZIONE DIGITALE?
        </Typography>
        <Box sx={{ padding: "0px 0px 24px 0px" }}>
          <Card sx={{ border: "1px solid beige" }}>
            <Typography
              fontFamily={themeFonts.regular}
              sx={{ padding: "16px 16px 0px 16px", textAlign: "left" }}
            >
              Se hai già acquistato e ritirato delle capsule in un N-Point
              Mediaworld, inserisci l’email usata in fase di acquisto per
              recuperare automaticamente i tuoi dati e effettuare velocemente
              l’ordine.
            </Typography>
            <Grid sx={{ padding: "16px" }} container>
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                lg={4}
                sx={{ textAlign: "center" }}
              >
                <InputLabel
                  sx={{
                    textAlign: "initial",
                    fontFamily: themeFonts.regular,
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    color:themeColors.text.primary
                  }}
                  htmlFor="bootstrap-input"
                >
                  Email
                </InputLabel>
                <TextField
                  size="small"
                  className="text-input"
                  fullWidth
                  id="bootstrap-input"
                />
              </Grid>

              <Grid
                className="check"
                sx={{ textAlign: "end" }}
                item
                xs={12}
                md={12}
                sm={12}
                lg={3}
              >
                <Box component={"div"} className="check-btn" >
                  <Button
                    sx={{
                      padding: "17px 24px 15px 24px",
                      width: "100%",
                      backgroundColor: themeColors.maincolor.tertiary,
                      "&:focus": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                      "&:hover": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                      "&:active": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                    }}
                    variant="contained"
                  >
                    <Typography
                      fontSize={"16px"}
                      fontWeight={"400"}
                      lineHeight={"16px"}
                      letterSpacing={"0.96px"}
                      textTransform={"uppercase"}
                      fontFamily={themeFonts.medium}
                    >
                      Recupera dati
                    </Typography>
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} md={12} sm={12} lg={2}>
                <Typography
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="18px"
                  letterSpacing={"1px"}
                  fontFamily={themeFonts.medium}
                  sx={{ textAlign: "center" }}
                >
                  oppure
                </Typography>
              </Grid>
              <Grid
                className="check-next"
                sx={{ padding: "24px" }}
                xs={12}
                md={12}
                sm={12}
                lg={3}
              >
                <Box component={"div"} className="check-btn">
                  <Button
                    sx={{
                      padding: "17px 24px 15px 24px",
                      width: "100%",
                      backgroundColor: themeColors.maincolor.tertiary,
                      "&:focus": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                      "&:hover": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                      "&:active": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                    }}
                    variant="contained"
                  >
                    <Typography
                      fontSize={"16px"}
                      fontWeight={"400"}
                      lineHeight={"16px"}
                      letterSpacing={"0.96px"}
                      textTransform={"uppercase"}
                      fontFamily={themeFonts.medium}
                    >
                      PROSEGUI
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Container>
    </>
  );
};
export default ContinueCheckoutPage;
