import { Typography, styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";

const TypographyLabelSmall = styled(Typography)(({ theme, color }) => ({
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '13px',
  fontFamily: themeFonts.medium,
  letterSpacing: '1px',
  textTransform: 'uppercase',
}));

export default TypographyLabelSmall;