import { styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";

const H6 = styled('h6')(({ theme, color }) => ({
  fontWeight: 700,
  fontSize: '16px',
  fontFamily: themeFonts.bold,
  lineHeight: '19.2px',
  letterSpacing: '1px',
  textTransform: 'uppercase'
}));

export default H6;