import { styled } from "@mui/material";
import TypographyLabelBase from "./TypographyLabelBase";

const TypographyLabelBaseBold = styled(TypographyLabelBase)(
  ({ theme, color }) => ({
    fontWeight: 700,
  })
);

export default TypographyLabelBaseBold;
