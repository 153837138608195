import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { CircularProgress, Divider, Typography } from "@mui/material";
import config from "../config";
import { accessToken } from "../Constant/token";
import axios from "axios";
import "../assets/Css/mainOriginalList.css";
import "../assets/Css/mainOriginalListRes.css";
import { useState, useRef, useEffect } from "react";
import ModalInSearch from "./SearchModal";
import { useNavigate } from "react-router-dom";
import { baseImageUrl } from "../Constant/constData";

import { themeColors } from "../Constant/themeColor";
import { themeFonts } from "../Constant/themeFont";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: "0px 16px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#626971",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function SearchAppBar({ showSearch, setShowSearch, storeCode }) {
  const [searchedData, setSearchedData] = useState([]);
  const [found, setFound] = useState(0);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleChevronClick = () => {
    setShowSearch(false);
  };

  const handleRedirect = (sku) => {
    setShowSearch(false);
    navigate(`/coffeeDetails/${sku}?store=${storeCode}`);
  };

  useEffect(() => {
    console.log("searchedData", searchedData);
  }, [found]);

  const getAllSearchItems = async (value) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/products?searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=%${value}%&searchCriteria[filter_groups][0][filters][0][condition_type]=like
    `;
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const data = response.data;

      setSearchedData(data.items);
      setFound(data.items?.length);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Search className="search-input">
        <SearchIconWrapper>
          <Box
            spacing="2px"
            component={"img"}
            src={`${baseImageUrl}/Chevron.svg`}
            alt="searchArrow"
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "1px",
              height: "24px",
              bgcolor: "background.paper",
              color: "#E5E8E8",
              "& svg": {
                m: 1.5,
              },
              "& hr": {
                mx: 0.5,
              },
            }}
          ></Box>
          <Divider orientation="vertical" variant="middle" flexItem />
        </SearchIconWrapper>

        <StyledInputBase
          placeholder="Cerca prodotto..."
          ref={inputRef}
          inputProps={{
            "aria-label": "search",
            onChange: (e) => getAllSearchItems(e.target.value),
          }}
        />

        {loading ? (
          <CircularProgress size={"20px"} sx={{ color: "#000" }} />
        ) : (
          <Box
            onClick={handleChevronClick}
            component={"img"}
            src={`${baseImageUrl}/close.svg`}
            alt="close"
            className="close-input-btn"
          />
        )}
      </Search>

      {found > 0 ? (
        <ModalInSearch
          found={found}
          searchedData={searchedData}
          handleRedirect={handleRedirect}
        />
      ) : (
        <>
          <Divider />
          <Typography
            margin={"10px"}
            variant={"h4"}
            fontFamily={themeFonts.medium}
            fontSize={"18px"}
            fontWeight={400}
            lineHeight={"18px"}
            sx={{
              color: themeColors.text.black,
              textAlign: "center",
            }}
          >
            Nessuna capsula trovata
          </Typography>
        </>
      )}
    </Box>
  );
}
