import { useEffect, useState } from "react";
import { Box, Container, Typography, Card } from "@mui/material";
import { themeColors } from "../../Constant/themeColor";
import "../../assets/Css/mainOriginalList.css";
import "../../assets/Css/mainOriginalListRes.css";
import { themeFonts } from "../../Constant/themeFont";
import ProgressIndicator from "../../components/ProgressIndicator";
import { useNavigate } from "react-router-dom";
import { accessToken } from "../../Constant/token";
import config from "../../config";
import axios from "axios";
import { baseImageUrl } from "../../Constant/constData";

const Home = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [homeData, setHomeData] = useState();

  useEffect(() => {
    getAllHomeData();
  }, []);

  const getAllHomeData = async () => {
    setLoading(true);
    try {
      const url = config.placeholderApiUrl + "/V1/stores/lists";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios({
        method: "get",
        url,
        headers,
      });

      setHomeData(response.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <ProgressIndicator />
      ) : (
        <Box component={"div"} bgcolor={themeColors.common.pillWhite}>
          <Box
            component={"img"}
            src={homeData?.banner_image}
            alt="banner"
            className="img-fluid"
            width={"100%"}
            height={"211px"}
            sx={{ objectFit: "cover" }}
          />
          <Container>
            <Box component={"div"}>
              <Typography
                variant="h4"
                fontFamily={themeFonts.bold}
                fontWeight={"700"}
                fontSize={"24px"}
                lineHeight={"28px"}
                letterSpacing={"1px"}
                sx={{
                  padding: "32px 0px 16px 0px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {homeData?.main_title}
              </Typography>
              <Box>
                <Card
                  sx={{
                    borderRadius: "0px",
                    background: "#fff",
                    boxShadow:
                      "0px 0px 8px 0px rgba(23, 23, 26, 0.08), 0px 2px 8px 0px rgba(23, 23, 26, 0.05)",
                  }}
                >
                  <Typography
                    fontFamily={themeFonts.regular}
                    sx={{ padding: "16px", textAlign: "left" }}
                  >
                    {homeData?.main_description}
                  </Typography>
                </Card>
              </Box>
            </Box>
            <Box component={"div"} padding={"0px 0px 40px 0px"}>
              <Typography
                variant="h4"
                fontFamily={themeFonts.bold}
                fontWeight={"700"}
                fontSize={"20px"}
                lineHeight={"24px"}
                letterSpacing={"1px"}
                sx={{
                  padding: "24px 0px 16px 0px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Elenco store
              </Typography>

              {homeData?.items.map((element) => {
                return (
                  <Box
                    key={element.store_id}
                    sx={{ padding: "0px 0px 8px 0px" }}
                  >
                    <Card
                      onClick={() =>
                        navigate(`/originalList?store=${element.store_code}`)
                      }
                      sx={{
                        background: "#fff",
                        boxShadow:
                          "0px 0px 8px 0px rgba(23, 23, 26, 0.08), 0px 2px 8px 0px rgba(23, 23, 26, 0.05)",
                        padding: "12px 4px 12px 16px",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography
                          fontFamily={themeFonts.regular}
                          fontWeight={"600"}
                          fontSize={"16px"}
                          lineHeight={"normal"}
                          sx={{ textAlign: "left" }}
                        >
                          {element.name}
                        </Typography>

                        <Typography
                          fontFamily={themeFonts.regular}
                          fontWeight={"400"}
                          fontSize={"13px"}
                          lineHeight={"normal"}
                          color={themeColors.text.secondary}
                          sx={{ textAlign: "left" }}
                        >
                          {element.address}
                        </Typography>
                      </Box>
                      <Box
                        src={`${baseImageUrl}/arrowRight.svg`}
                        alt="arrow right"
                        component={"img"}
                      ></Box>
                    </Card>
                  </Box>
                );
              })}
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Home;
