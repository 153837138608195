import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { themeColors } from "../Constant/themeColor";
import ".././assets/Css/mainOriginalList.css";
import ".././assets/Css/mainOriginalList.css";
import { themeFonts } from "../Constant/themeFont";
import { baseImageUrl } from "../Constant/constData";

const ModalInSearch = ({ searchedData, handleRedirect, found }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <Grid xs={12} sm={12} md={12} lg={12} padding={0}>
          <Grid container className="maincardtwo">
            <Box
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <nav aria-label="main mailbox folders">
                <List className="list-parent" sx={{ padding: "0px" }}>
                  {searchedData.map((element) => {
                    return (
                      <>
                        <Divider />
                        <ListItem sx={{ padding: "16px 0px 16px 0px" }}>
                          <ListItemIcon>
                            <Box
                              component={"img"}
                              src={element.extension_attributes.product_image}
                              alt="productImage"
                              className="cartList-img"
                            />
                          </ListItemIcon>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography
                              className="roma-h6"
                              variant="h6"
                              fontFamily={themeFonts.medium}
                              fontSize={"16px"}
                              fontWeight={700}
                              lineHeight={"19px"}
                              letterSpacing={"1px"}
                              textTransform={"uppercase"}
                              sx={{
                                color: themeColors.text.primary,
                                textAlign: "center",
                                margin: "4px 0px",
                              }}
                            >
                              {element.name}
                            </Typography>
                            <Typography
                              fontFamily={themeFonts.medium}
                              variant="h6"
                              color={themeColors.maincolor.tertiary}
                              className="price-h6"
                              sx={{ margin: "16px 0px" }}
                            >
                              € {element.price}
                            </Typography>
                          </Grid>
                          <ListItemButton sx={{ paddingRight: "0px" }}>
                            <Grid
                              item
                              xs={2}
                              sm={2}
                              sx={{
                                display: {
                                  xs: "block",
                                  md: "none",
                                },
                              }}
                            >
                              <Box component={"div"} className="close-icon">
                                <Box
                                  onClick={() => {
                                    handleRedirect(element.sku);
                                  }}
                                  component={"img"}
                                  src={`${baseImageUrl}/arrowRight.svg`}
                                  alt="close"
                                  className="cross-close"
                                />
                              </Box>
                            </Grid>
                          </ListItemButton>
                        </ListItem>
                      </>
                    );
                  })}
                </List>
              </nav>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default ModalInSearch;
