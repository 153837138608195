import { Typography, styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";

export const StyleBodyXSmall = {
  fontWeight: 400,
  fontSize: '12px',
  fontFamily: themeFonts.regular,
  lineHeight: '15.6px',
  letterSpacing: '1px'
};

const TypographyBodyXSmall = styled(Typography)(({ theme, color }) => (StyleBodyXSmall));

export default TypographyBodyXSmall;