import {
  Box,
  Container,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  Avatar,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { themeColors } from "../../Constant/themeColor";
import "../../assets/Css/mainOriginalList.css";
import "../../assets/Css/mainOriginalListRes.css";
import { themeFonts } from "../../Constant/themeFont";
import { accessToken } from "../../Constant/token";
import config from "../../config";
import { useEffect, useState } from "react";
import axios from "axios";
import QuantityModal from "../../components/QuantityModal";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressIndicator from "../../components/ProgressIndicator";
import { baseImageUrl } from "../../Constant/constData";

const customAvatarStyle = {
  backgroundColor: themeColors.maincolor.tertiary,
};

const CartList = ({ getCartCount }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeCode = searchParams.get("store");

  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [cartItems, setCartItems] = useState();
  const [cartTotal, setCartTotal] = useState();
  const [open, setOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemSku, setSelectedItemSku] = useState(null);

  const cartId = localStorage.getItem("cartId");

  const handleOpen = () => {
    // console.log("handleOpen called");
    setOpen(true);
  };

  useEffect(() => {
    if (cartId) {
      getAllCartItems(cartId);
      getAllCartTotal(cartId);
    }
  }, [storeCode]);

  const getAllCartItems = async (id) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${id}
    `;
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const data = response.data;

      setCartItems(data);
      getCartCount(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAllCartTotal = async (id) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${id}/totals
    `;
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const data = response.data;

      setCartTotal(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const removeCartItems = async (itemId) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${cartId}/items/${itemId}
    `;
    setCartLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "delete",
        url,
        headers,
      });
      getAllCartItems(cartId);
      getAllCartTotal(cartId);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSelectedItemId(null);
      setCartLoading(false);
    }
  };

  const updateCartItems = async (itemId, qty, sku) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${cartId}/items/${itemId}
    `;
    setCartLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let value = {
        cartItem: {
          sku: sku,
          qty: qty,
          quote_id: cartId,
        },
      };

      const response = await axios({
        method: "put",
        url,
        headers,
        data: value,
      });

      getAllCartItems(cartId);
      getAllCartTotal(cartId);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSelectedItemId(null);
      setSelectedItemSku(null);
      setCartLoading(false);
    }
  };

  return (
    <>
      {loading && <ProgressIndicator />}
      <Box
        sx={{
          paddingTop: "24px",
          paddingBottom: "24px",
        }}
      >
        <Container maxWidth="md">
          <Grid sx={{ justifyContent: "center" }} container>
            <Box
              sx={{
                paddingBottom: "16px",
              }}
            >
              <Typography
                variant="h4"
                fontFamily={themeFonts.bold}
                fontSize={"24px"}
                fontWeight={700}
                textTransform={"uppercase"}
                lineHeight={"28px"}
                letterSpacing={"1px"}
                sx={{
                  color: themeColors.text.black,
                  justifyContent: "center",
                }}
              >
                CARRELLO
              </Typography>
            </Box>
          </Grid>
          <QuantityModal
            open={open}
            setOpen={setOpen}
            onSelected={(selectedQuantity) => {
              updateCartItems(
                selectedItemId,
                selectedQuantity,
                selectedItemSku
              );
            }}
          />
          <Grid xs={12} sm={12} md={3} lg={3}>
            <Grid container className="maincardtwo">
              <Box
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <nav aria-label="main mailbox folders">
                  <List className="list-parent" sx={{ padding: "0px" }}>
                    {cartItems?.items.map((element, index) => {
                      return (
                        <Box component={"div"} key={element.item_id}>
                          <ListItem sx={{ padding: "16px" }}>
                            <ListItemIcon>
                              <Box
                                component={"img"}
                                src={element?.extension_attributes?.image}
                                alt="productImage"
                                className="cartList-img"
                              />
                            </ListItemIcon>
                            <Grid item xs={8} sm={8} md={12} lg={12}>
                              <Typography
                                className="roma-h6"
                                variant="h6"
                                fontFamily={themeFonts.medium}
                                fontSize={"16px"}
                                fontWeight={700}
                                lineHeight={"19px"}
                                letterSpacing={"1px"}
                                textTransform={"uppercase"}
                                sx={{
                                  color: themeColors.text.primary,
                                  textAlign: "center",
                                  margin: "4px 0px",
                                }}
                              >
                                {element.name}
                              </Typography>
                              <Typography
                                fontFamily={themeFonts.medium}
                                variant="h6"
                                color={themeColors.common.brown}
                                className="price-h6"
                                sx={{ margin: "16px 0px" }}
                              >
                                €{(element.price * element.qty).toFixed(2)}{" "}
                                <Box
                                  component={"span"}
                                  fontFamily={themeFonts.light}
                                >
                                  (€ {element.price.toFixed(2)})
                                </Box>
                              </Typography>
                            </Grid>
                            <ListItemButton>
                              <Grid item xs={2} sm={2} md={12} lg={12}>
                                <Avatar
                                  onClick={() => {
                                    handleOpen();
                                    setSelectedItemId(element.item_id);
                                    setSelectedItemSku(element.sku);
                                  }}
                                  style={customAvatarStyle}
                                >
                                  {cartLoading &&
                                  selectedItemId == element.item_id ? (
                                    <CircularProgress
                                      size={"20px"}
                                      sx={{ color: "#fff" }}
                                    />
                                  ) : (
                                    <Typography fontFamily={themeFonts.medium}>
                                      {element.qty}
                                    </Typography>
                                  )}
                                </Avatar>
                              </Grid>
                            </ListItemButton>
                            <ListItemButton sx={{ paddingRight: "0px" }}>
                              <Grid
                                item
                                xs={2}
                                sm={2}
                                sx={{
                                  display: {
                                    xs: "block",
                                    md: "none",
                                  },
                                }}
                              >
                                <Box component={"div"} className="close-icon">
                                  <Box
                                    onClick={() => {
                                      removeCartItems(element.item_id);
                                      setSelectedItemId(element.item_id);
                                    }}
                                    component={"img"}
                                    src={`${baseImageUrl}/close.svg`}
                                    alt="close"
                                    className="cross-close"
                                  />
                                </Box>
                              </Grid>
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </Box>
                      );
                    })}
                  </List>
                </nav>
              </Box>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TableContainer component={Paper} className="my-table">
                  <Table sx={{ minWidth: 6 }} aria-label="caption table">
                    <TableBody>
                      {cartItems?.items.length > 0 ? (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                padding: "8px 0px 8px 0px",
                                fontFamily: themeFonts.regular,
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "18px",
                                letterSpacing: "1px",
                                color: themeColors.text.primary,
                              }}
                            >
                              Subtotale
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                padding: "8px 0px 8px 0px",
                                fontFamily: themeFonts.regular,
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "18px",
                                letterSpacing: "1px",
                                color: themeColors.text.primary,
                              }}
                            >
                              {cartTotal?.subtotal} €
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                padding: "8px 0px 8px 0px",
                                fontFamily: themeFonts.regular,
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "18px",
                                letterSpacing: "1px",
                                color: themeColors.text.primary,
                              }}
                            >
                              IVA
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                padding: "8px 0px 8px 0px",
                                fontFamily: themeFonts.regular,
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "18px",
                                letterSpacing: "1px",
                                color: themeColors.text.primary,
                              }}
                            >
                              {cartTotal?.tax_amount} €
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                padding: "8px 0px 8px 0px",
                                fontFamily: themeFonts.regular,
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "18px",
                                letterSpacing: "1px",
                                color: themeColors.text.primary,
                              }}
                            >
                              Totale IVA escl.
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                padding: "8px 0px 8px 0px",
                                fontFamily: themeFonts.regular,
                              }}
                            >
                              {cartTotal?.subtotal} €
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                padding: "8px 0px 8px 0px",
                                fontFamily: themeFonts.bold,
                                fontWeight: 700,
                                fontSize: "16x",
                                lineHeight: "20.8px",
                                letterSpacing: "1px",
                                color: themeColors.text.primary,
                              }}
                            >
                              Totale IVA incl.
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                padding: "8px 0px 8px 0px",
                                fontFamily: themeFonts.bold,
                                fontWeight: 700,
                                fontSize: "16x",
                                lineHeight: "20.8px",
                                letterSpacing: "1px",
                                color: themeColors.text.primary,
                              }}
                            >
                              {cartTotal?.subtotal_incl_tax} €
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            sx={{
                              padding: "8px 8px 8px 8px",
                              fontFamily: themeFonts.regular,
                              fontWeight: 700,
                              fontSize: "22px",
                              lineHeight: "20px",
                              letterSpacing: "1px",
                            }}
                          >
                            il carrello è vuoto
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>

                  <Box component={"div"} className="table-btn-div">
                    <Button
                      onClick={() => {
                        cartItems?.items.length > 0
                          ? navigate(`/checkout?store=${storeCode}`)
                          : navigate(`/originalList?store=${storeCode}`);
                      }}
                      variant="contained"
                      sx={{
                        boxShadow: "none",
                        padding: "17px 24px 15px 24px",
                        backgroundColor: themeColors.maincolor.tertiary,
                        "&:focus": {
                          backgroundColor: themeColors.maincolor.tertiary,
                        },
                        "&:hover": {
                          backgroundColor: themeColors.maincolor.tertiary,
                        },
                        "&:active": {
                          backgroundColor: themeColors.maincolor.tertiary,
                        },
                      }}
                      className="table-btn"
                    >
                      <Typography
                        variant="btn"
                        fontFamily={themeFonts.medium}
                        fontSize={"16px"}
                        lineHeight={"16px"}
                        fontWeight={400}
                        textTransform={"uppercase"}
                        letterSpacing={"0.96px"}
                        sx={{
                          color: "#FFF",
                          textAlign: "center",
                        }}
                      >
                        {cartItems?.items.length > 0
                          ? "Procedi al checkout"
                          : "Torna al catalogo"}
                      </Typography>
                    </Button>
                  </Box>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CartList;
