import Select, { components } from "react-select";

import { StyleBodySmall } from "./TypographyBodySmall";
import { baseImageUrl } from "../Constant/constData";

const NespressoSelect = (props) => {
  const { regionId, name, selectedValue, setFieldValue } = props;

  const options2 = regionId;

  const DropdownIndicator = (props) => {
    const {
      selectProps: { menuIsOpen },
    } = props;

    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? (
          <img
            src={`${baseImageUrl}/ic-chevron-up.svg`}
            style={{
              width: 16,
              height: 16,
            }}
          />
        ) : (
          <img
            src={`${baseImageUrl}/chevronDown.svg`}
            style={{
              width: 16,
              height: 16,
            }}
          />
        )}
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <Select
        name={name}
        value={options2?.find((o) => o.value == selectedValue)}
        onChange={(e) => {
          setFieldValue(name, e.label);
        }}
        options={options2}
        components={{ DropdownIndicator }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...StyleBodySmall,
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            height: "14px",
          }),
          indicatorSeparator: (baseStyles, state) => ({
            display: "none",
          }),
          option: (base, state) => ({
            ...base,
            ...StyleBodySmall,
          }),
        }}
      />
    </>
  );
};

export default NespressoSelect;
