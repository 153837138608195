import { Stack, styled } from "@mui/material";
import { themeColors } from "../Constant/themeColor";

const Container = styled(Stack)((props) => ({
  direction: 'column',
  padding: '0px 16px 24px',
  backgroundColor: themeColors.common.pillWhite
}));

export default Container;
