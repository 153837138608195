import { Box, Stack } from "@mui/material";
import TypographyBodySmall, { StyleBodySmall } from "./TypographyBodySmall";
import { themeColors } from "../Constant/themeColor";

const LabelledInputMaterial = (props) => {
  const { label, required } = props;
  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };
  return (
    <Stack direction="column" alignItems="start" mb={props.error ? 0 : 2}>
      <TypographyBodySmall
        sx={{
          color: themeColors.text.primary,
        }}
      >
        {label}{" "}
        {required && (
          <Box component={"span"} sx={{ color: themeColors.common.error }}>
            *
          </Box>
        )}
      </TypographyBodySmall>

      <input
        onKeyDown={handleKeyDown}
        {...props}
        style={{
          width: "100%",
          margin: "0px 0px 0px",
          minWidth: "fit-content",
          border: `1px solid ${themeColors.common.surfaceGray}`,
          borderRadius: "4px",
          padding: "10px",
          color: themeColors.text.primary,
          wordWrap: "break-word",
          ...StyleBodySmall,
        }}
      />
    </Stack>
  );
};

export default LabelledInputMaterial;
