import { styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";

const H4 = styled('h4')(({ theme, color }) => ({
  fontWeight: 700,
  fontSize: '24px',
  fontFamily: themeFonts.bold,
  lineHeight: '28.8px',
  letterSpacing: '1px',
  color: "black",
  textTransform: 'uppercase'
}));

export default H4;