import React, { useEffect, useState } from "react";
import { Stack, AccordionSummary, Divider } from "@mui/material";
import parse from "html-react-parser";

import { CenterHeading } from "./Checkout";
import {
  StyledAccordion,
  StyledAccordionDetails,
} from "./CoffeeDetails";
import TypographyBodyBase from "../components/TypographyBodyBase";
import Card from "../components/Card";
import ProgressIndicator from "../components/ProgressIndicator";
import Container from "../components/Container";

import axios from "axios";
import { accessToken } from "../Constant/token";
import config from "../config";
import { baseImageUrl } from "../Constant/constData";

const FAQ = () => {
  const [faqs, setFaqs] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllFaqData();
  }, []);

  const getAllFaqData = async () => {
    try {
      const url = config.placeholderApiUrl + "/V1/faq/lists/?searchCriteria";
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const results = await axios({
        method: "get",
        url,
        headers,
      });

      const data = results.data;

      setFaqs(data.items);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Container>
        <CenterHeading>DOMANDE FREQUENTI</CenterHeading>
        <Card
          bgcolor="white"
          sx={{
            padding: "10px 16px 16px 16px",
          }}
        >
          <Stack
            direction="column"
            flex="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            {loading ? (
              <ProgressIndicator />
            ) : (
              <>
                {faqs?.map((faq, index) => {
                  return (
                    <>
                      <StyledAccordion>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          expandIcon={
                            <img src={`${baseImageUrl}/chevronDown.svg`} />
                          }
                          sx={{
                            margin: "16px 0px",
                            textAlign: "left",
                            padding: "0px",
                          }}
                        >
                          <TypographyBodyBase
                            textAlign="start"
                            sx={{
                              textTransform: "uppercase",
                            }}
                          >
                            {faq.title}
                          </TypographyBodyBase>
                        </AccordionSummary>
                        <StyledAccordionDetails>
                          <TypographyBodyBase
                            textAlign="start"
                            sx={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {parse(faq.content)}
                          </TypographyBodyBase>
                        </StyledAccordionDetails>
                      </StyledAccordion>
                      {index < faqs?.length - 1 && (
                        <Divider
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Stack>
        </Card>
      </Container>
    </>
  );
};

export default FAQ;
