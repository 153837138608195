import { styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";

const H5 = styled('h5')(({ theme, color }) => ({
  fontWeight: 700,
  fontSize: '20px',
  fontFamily: themeFonts.bold,
  lineHeight: '24px',
  letterSpacing: '1px',
  textTransform: 'uppercase'
}));

export default H5;