import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OriginalList from "./Pages/OriginalList/originalList";
import CoffeeDetails from "./Pages/CoffeeDetails";
import ThankYou from "./Pages/ThankYou";
import FAQ from "./Pages/FAQ";
import Checkout from "./Pages/Checkout";
import Footer from "./Pages/Footer/Footer";
import CartList from "./Pages/CartList/cartList";
import { Box } from "@mui/material";
import { themeColors } from "./Constant/themeColor";
import ContinueCheckoutPage from "./Pages/ContinueCheckout/continueCheckout";
import ProceedCheckout from "./Pages/ProceedCheckout/proceedCheckout";
import Home from "./Pages/Home/home";
import Header from "./Pages/Header/header";
import { useState } from "react";
import AdyenCreditPayment from "./Pages/Adyen/adyencreditCard";

// import AdyenGooglePay from "./Pages/Adyen/adyenGpay";
// import AdyenApplePay from "./Pages/Adyen/adyenApplePay";
export const PATH_COFFEE_LIST_ORIGINAL = "/originalList";

function App() {
  const [categoryData, setCategoryData] = useState();
  const [cartCount, setCartCount] = useState(null);

  const getAllCategoryData = (data) => {
    setCategoryData(data);
  };

  const getCartCount = (data) => {
    setCartCount(data);
  };

  return (
    <>
      <BrowserRouter>
        <Header getAllCategoryData={getAllCategoryData} cartCount={cartCount} />

        <Box
          component={"div"}
          sx={{
            backgroundColor: themeColors.common.pillWhite,
          }}
        >
          <Routes>
            <Route exact={true} path={`/`} element={<Home />} />
            <Route
              exact={true}
              path={`/${PATH_COFFEE_LIST_ORIGINAL}`}
              element={<OriginalList categoryData={categoryData} />}
            />
            <Route
              exact={true}
              path={`/coffeeDetails/:sku`}
              element={<CoffeeDetails getCartCount={getCartCount} />}
            />
            <Route exact={true} path={`/thankyou`} element={<ThankYou />} />
            <Route exact={true} path={`/faq`} element={<FAQ />} />
            <Route
              exact={true}
              path={`/checkout`}
              element={<Checkout getCartCount={getCartCount} />}
            />

            <Route
              exact={true}
              path={`/cartList`}
              element={<CartList getCartCount={getCartCount} />}
            />
            <Route
              exact={true}
              path={`/continue`}
              element={<ContinueCheckoutPage />}
            />
            <Route
              exact={true}
              path={`/proceedCheckout`}
              element={<ProceedCheckout />}
            />
            <Route
              exact={true}
              path={`/adyen`}
              element={<AdyenCreditPayment />}
            />
            {/* <Route
              exact={true}
              path="/adyenGpay"
              element={<AdyenGooglePay />}
            /> */}
            {/* <Route
              exact={true}
              path="/adyenApplePay"
              element={<AdyenApplePay />}
            /> */}
          </Routes>
        </Box>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
