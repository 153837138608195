import { Typography, styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";

const TypographyLabelLarge = styled(Typography)(({ theme, color }) => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18.2px',
  fontFamily: themeFonts.light,
  letterSpacing: '1px'
}));

export default TypographyLabelLarge;