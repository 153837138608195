import { Radio as BaseRadio, Stack, Switch, styled } from "@mui/material";
import TypographyBodySmall from "./TypographyBodySmall";

import TypographyBodyXSmall from "./TypographyBodyXSmall";
import { baseImageUrl } from "../Constant/constData";

const LabelledSwitch = (props) => {
  const { label, content, checked } = props;

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: "60px",
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  return (
    <Stack
      // sx={{
      //   alignItems: "center",
      // }}
      direction="row"
      alignItems="top"
      gap={1}
      padding={"0px"}
      paddingBottom={label == "Seleziona tutte" ? "0px" : "16px"}
    >
      <Android12Switch {...props} color="success" checked={checked} />

      {content ? (
        <Stack gap={0.5}>
          <TypographyBodySmall textAlign="start">{label}</TypographyBodySmall>
          <TypographyBodyXSmall textAlign="start">
            {content}
          </TypographyBodyXSmall>
        </Stack>
      ) : (
        <TypographyBodySmall textAlign="start">{label}</TypographyBodySmall>
      )}
    </Stack>
  );
};

export default LabelledSwitch;
