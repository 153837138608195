import { Stack, styled } from "@mui/material";
import TypographyLabelLarge from "./TypographyLabelLarge";
import { themeColors } from "../../src/Constant/themeColor";

const LabelText = styled(TypographyLabelLarge)(({ theme }) => ({
  color: themeColors.text.secondary,
}));

const QuantityCell = ({ label, onClick }) => {
  const color = themeColors.common.surfaceGray;
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 'auto',
        height: 'fit-content',
        padding: '16px 8px'
      }}
      borderRight={`1px solid ${color}`}
      onClick={() => {
        onClick(label);
      }}
    >
      <LabelText textAlign="center">{label}</LabelText>
    </Stack>
  );
};

export default QuantityCell;
