import { Radio as BaseRadio, Stack } from "@mui/material";
import TypographyBodySmall from "./TypographyBodySmall";

import TypographyBodyXSmall from "./TypographyBodyXSmall";
import { baseImageUrl } from "../Constant/constData";

const StyleWithContent = {
  width: "16px",
  height: "16px",
  "& > input": {
    width: "17px",
    height: "17px",
  },
};

const LabelledRadio = (props) => {
  const { label, content } = props;

  return (
    <Stack direction="row" alignItems="top" gap={1}>
      <BaseRadio
        {...props}
        sx={content ? StyleWithContent : StyleWithContent}
        size="small"
        icon={<img src={`${baseImageUrl}/ic-radio-unchecked.svg`} />}
        checkedIcon={<img src={`${baseImageUrl}/ic-radio-checked.svg`} />}
      />
      {content ? (
        <Stack gap={0.5}>
          <TypographyBodySmall textAlign="start">{label}</TypographyBodySmall>
          <TypographyBodyXSmall textAlign="start">
            {content}
          </TypographyBodyXSmall>
        </Stack>
      ) : (
        <TypographyBodySmall textAlign="start">{label}</TypographyBodySmall>
      )}
    </Stack>
  );
};

export default LabelledRadio;
