import {
  Box,
  Container,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  Avatar,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { themeColors } from "../../Constant/themeColor";
import "../../assets/Css/mainOriginalList.css";
import "../../assets/Css/mainOriginalListRes.css";
import { themeFonts } from "../../Constant/themeFont";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { accessToken } from "../../Constant/token";
import ProgressIndicator from "../../components/ProgressIndicator";
import { baseImageUrl } from "../../Constant/constData";

const customAvatarStyle = {
  backgroundColor: themeColors.text.primary,
};

const ProceedCheckout = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const [checkoutItems, setCheckoutItems] = useState();
  const [checkoutTotal, setCheckoutTotal] = useState();
  const [selectedPayment, setSelectedPayment] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeCode = searchParams.get("store");
  const customerEmail = searchParams.get("email");

  const cartId = localStorage.getItem("cartId");

  console.log("cartId", typeof cartId);

  useEffect(() => {
    if (cartId) {
      getAllCartItems(cartId);
      getAllCartTotal(cartId);
      getAllpaymentMethod(cartId);
    }
  }, [storeCode]);

  const getAllCartItems = async (id) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${id}
    `;
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const data = response.data;
      setCheckoutItems(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const paymentInformation = async () => {
    setLoading(true);
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${cartId}/payment-information`;

    const data = {
      cartId: cartId,
      paymentMethod: {
        method: "adyen",
        additional_data: null,
      },
      email: customerEmail,
    };

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "post",
        url,
        headers,
        data: data,
      });

      console.log("response.data", response.data);
      generateComponent(response.data)
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const generateComponent = async (id) => {
    let url =
      config.placeholderApiUrl +
      `/V1/adyen/generate_component
      
    `;
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const values = {
        return_url: `http://localhost:3000/thankyou?store=${storeCode}`,
        order_id : id
      };
      const { data } = await axios({
        method: "post",
        url,
        headers,
        data: values,
      });

      console.log("response url link", data);
      window.open(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAllCartTotal = async (id) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${id}/totals
    `;
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const data = response.data;

      setCheckoutTotal(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllpaymentMethod = async (id) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${id}/payment-methods
    `;
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const data = response.data;
      // console.log("data ", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPaypalRedirectionLink = async (paymentType) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/paypalapi/createpaypalexpresstoken
    `;
    if (paymentType == "paypal") {
      const values = {
        cart_id: cartId,
        cancel_url: `https://nespromo.it/digital-point/originalList?store=${storeCode}`,
        return_url: `https://nespromo.it/digital-point/thankyou?store=${storeCode}`,
        // cancel_url: "http://localhost:3000/",
        // return_url: `http://localhost:3000/thankyou?store=${storeCode}`,
      };

      setCheckoutLoading(true);

      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        const response = await axios({
          method: "post",
          url,
          headers,
          data: values,
        });
        const data = response.data;
        window.location.href = data;
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setCheckoutLoading(false);
      }
    }
  };

  return (
    <>
      {loading && <ProgressIndicator />}
      <Box
        sx={{
          paddingTop: "24px",
          paddingBottom: "24px",
        }}
      >
        <Container maxWidth="md">
          <Grid sx={{ justifyContent: "center" }} container>
            <Box
              sx={{
                paddingBottom: "16px",
              }}
            >
              <Typography
                variant="h4"
                fontFamily={themeFonts.bold}
                fontSize={"24px"}
                fontWeight={700}
                lineHeight={"28px"}
                letterSpacing={"1px"}
                textTransform={"uppercase"}
                sx={{
                  color: themeColors.text.black,
                  justifyContent: "center",
                }}
              >
                pagamento
              </Typography>
            </Box>
          </Grid>

          <Grid xs={12} sm={12} md={3} lg={3}>
            <Grid container className="maincardtwo">
              <Box
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <nav aria-label="main mailbox folders">
                  <List className="list-parent" sx={{ padding: "0px" }}>
                    {checkoutItems?.items.map((element) => {
                      return (
                        <Box component={"div"} key={element.item_id}>
                          <ListItem sx={{ padding: "16px 16px 16px 16px" }}>
                            <ListItemIcon>
                              <Box
                                component={"img"}
                                src={element.extension_attributes.image}
                                alt="productImage"
                                className="cartList-img"
                              />
                            </ListItemIcon>
                            <Grid item xs={9} sm={9} md={12} lg={12}>
                              <Typography
                                className="roma-h6"
                                variant="h6"
                                fontFamily={themeFonts.medium}
                                fontSize={"16px"}
                                fontWeight={700}
                                lineHeight={"19px"}
                                letterSpacing={"1px"}
                                textTransform={"uppercase"}
                                sx={{
                                  color: themeColors.text.primary,
                                  textAlign: "center",
                                  margin: "4px 0px",
                                }}
                              >
                                {element.name}
                              </Typography>
                              <Typography
                                fontFamily={themeFonts.medium}
                                variant="h6"
                                className="price-h6"
                                color={themeColors.common.brown}
                                sx={{ margin: "16px 0px" }}
                              >
                                €{(element.price * element.qty).toFixed(2)}{" "}
                                <Box
                                  component={"span"}
                                  fontFamily={themeFonts.light}
                                >
                                  (€{element.price.toFixed(2)})
                                </Box>
                              </Typography>
                            </Grid>
                            <ListItemButton sx={{ paddingRight: "0px" }}>
                              <Grid item xs={1} sm={1} md={12} lg={12}>
                                <Avatar style={customAvatarStyle}>
                                  <Typography fontFamily={themeFonts.medium}>
                                    {element.qty}
                                  </Typography>
                                </Avatar>
                              </Grid>
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </Box>
                      );
                    })}
                  </List>
                </nav>
              </Box>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TableContainer component={Paper} className="my-table">
                  <Table sx={{ minWidth: 6 }} aria-label="caption table">
                    <TableBody>
                      {/* {console.log("checkoutTotal", checkoutTotal)} */}
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            padding: "8px 0px 8px 0px",
                            fontFamily: themeFonts.regular,
                            letterSpacing: "1px",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        >
                          Subtotale
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            padding: "8px 0px 8px 0px",
                            fontFamily: themeFonts.regular,
                            letterSpacing: "1px",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        >
                          {checkoutTotal?.subtotal} €
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            padding: "8px 0px 8px 0px",
                            fontFamily: themeFonts.regular,
                            letterSpacing: "1px",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        >
                          IVA
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            padding: "8px 0px 8px 0px",
                            fontFamily: themeFonts.regular,
                            letterSpacing: "1px",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        >
                          {checkoutTotal?.tax_amount} €
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            padding: "8px 0px 8px 0px",
                            fontFamily: themeFonts.regular,
                            letterSpacing: "1px",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        >
                          Totale IVA escl.
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            padding: "8px 0px 8px 0px",
                            fontFamily: themeFonts.regular,
                            letterSpacing: "1px",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        >
                          {checkoutTotal?.subtotal} €
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            padding: "8px 0px 8px 0px",
                            fontFamily: themeFonts.regular,
                            letterSpacing: "1px",
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "20px",
                            borderBottom: "none",
                          }}
                        >
                          Totale IVA incl.
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            padding: "8px 0px 8px 0px",
                            fontFamily: themeFonts.regular,
                            letterSpacing: "1px",
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "20px",
                            borderBottom: "none",
                          }}
                        >
                          {checkoutTotal?.subtotal_incl_tax} €
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* {console.log("selectedPayment", selectedPayment)} */}
                <Box component={Paper} className="paymentCard">
                  <FormControl sx={{ margin: 0 }} variant="standard">
                    <RadioGroup
                      aria-labelledby="demo-error-radios"
                      name="paymentMethod"
                      onChange={(e) => setSelectedPayment(e.target.value)}
                    >
                      <FormControlLabel
                        sx={{ alignItems: "start" }}
                        value="adyen"
                        control={
                          <Radio
                            color="success"
                            sx={{
                              alignItems: "start",
                              padding: "0px 8px",
                              color: "#e5e8e8",
                            }}
                          />
                        }
                        label={
                          <Typography
                            fontFamily={themeFonts.regular}
                            fontSize={"16px"}
                            fontWeight={400}
                            lineHeight={"20px"}
                            letterSpacing={"1px"}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            Carta di debito o credito
                            <Box component={"div"} className="paymentMethods">
                              <Box
                                component={"img"}
                                src={`${baseImageUrl}/master.svg`}
                              />
                              <Box
                                component={"img"}
                                src={`${baseImageUrl}/visa.svg`}
                              />
                              {/* <Box
                                component={"img"}
                                src={`${baseImageUrl}/amex.svg`}
                              />
                              <Box
                                component={"img"}
                                src={`${baseImageUrl}/applepay.svg`}
                              />
                              <Box
                                component={"img"}
                                src={`${baseImageUrl}/samsungpay.png`}
                              />
                              <Box
                                component={"img"}
                                src={`${baseImageUrl}/gpay.png`}
                              /> */}
                            </Box>
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        value="paypal"
                        control={
                          <Radio sx={{ color: "#e5e8e8" }} color="success" />
                        }
                        label={
                          <Typography
                            fontFamily={themeFonts.regular}
                            fontSize={"16px"}
                            fontWeight={400}
                            lineHeight={"20px"}
                            letterSpacing={"1px"}
                          >
                            Paypal
                          </Typography>
                        }
                      />
                      <Box component={"div"} className="paypalMethod">
                        <Box
                          component={"img"}
                          src={`${baseImageUrl}/paypal.svg`}
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>

              <Box
                component={"div"}
                className="table-btn-div"
                sx={{ width: "100%" }}
              >
                {selectedPayment == "adyen" ? (
                  <Button
                    variant="contained"
                    className="table-btn"
                    onClick={() => {
                      paymentInformation();
                    }}
                    sx={{
                      boxShadow: "none",
                      padding: loading
                        ? "13px 20px 11px 20px"
                        : "17px 24px 15px 24px",
                      backgroundColor: themeColors.maincolor.tertiary,
                      "&:focus": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                      "&:hover": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                      "&:active": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
                    ) : (
                      "Conferma e paga"
                    )}
                  </Button>
                ) : (
                  <Button
                    disabled={selectedPayment ? false : true}
                    variant="contained"
                    className="table-btn"
                    onClick={() => {
                      if (selectedPayment == "paypal") {
                        getPaypalRedirectionLink(selectedPayment);
                      } else {
                        navigate(
                          `/adyen?store=${storeCode}&email=${customerEmail}`
                        );
                      }
                    }}
                    sx={{
                      boxShadow: "none",
                      padding: checkoutLoading
                        ? "13px 20px 11px 20px"
                        : "17px 24px 15px 24px",
                      backgroundColor: themeColors.maincolor.tertiary,
                      "&:focus": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                      "&:hover": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                      "&:active": {
                        backgroundColor: themeColors.maincolor.tertiary,
                      },
                    }}
                  >
                    {checkoutLoading ? (
                      <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
                    ) : (
                      <Typography
                        variant="btn"
                        fontFamily={themeFonts.medium}
                        fontSize={"16px"}
                        lineHeight={"16px"}
                        textTransform={"uppercase"}
                        letterSpacing={"0.96px"}
                        fontWeight={400}
                        sx={{
                          color: "#FFF",
                          textAlign: "center",
                          boxShadow: "none",
                        }}
                      >
                        Conferma e paga
                      </Typography>
                    )}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ProceedCheckout;
