import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import {
  Stack,
  Typography,
  Divider,
  styled,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  useMediaQuery,
  Box,
  CircularProgress,
} from "@mui/material";
import IntensityMaterial from "../../src/components/IntensityMaterial";
import StatisticsPanel from "../components/StatisticsPanel";
import "../assets/Css/mainOriginalList.css";
import "../assets/Css/mainOriginalListRes.css";
import { themeColors } from "../Constant/themeColor";
import TypographyBodyBase from "../components/TypographyBodyBase";
import Button from "../components/Button";
import TypographyLabelSmall from "../components/TypographyLabelSmall";
import H4 from "../components/H4";
import { themeFonts } from "../Constant/themeFont";
import TypographyLabelLargeMedium from "../components/TypographyLabelLargeMedium";
import Card from "../components/Card";
import TypographyBodyRegular from "../components/TypographyBodyRegular";
import config from "../config";
import { accessToken } from "../Constant/token";
import axios from "axios";
import QuantityModal from "../components/QuantityModal";
import ProgressIndicator from "../components/ProgressIndicator";
import { baseImageUrl } from "../Constant/constData";

export const StyledAccordion = styled(Accordion)(({ theme, color }) => ({
  boxShadow: "none",
  padding: "0px",
  "& .MuiAccordionSummary-root": {
    padding: "0px",
    margin: "0px",
    height: "56px",
  },
  "&:before": {
    display: "none",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme, color }) => ({
    padding: "0px",
    margin: "8px 0px 10px 0px",
  })
);

export const Spanned = styled("span")(({ theme, label }) => ({
  fontWeight: 700,
}));

const CoffeeDetails = ({ getCartCount }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeCode = searchParams.get("store");

  const { sku } = useParams();

  const [coffeeData, setCoffeeData] = useState();

  const [open, setOpen] = useState(false);
  const [showToaster, setShowToaser] = useState({
    show: false,
    qt: 0,
  });
  const [api, contextHolder] = notification.useNotification();
  const theme = useTheme();
  const tabletView = useMediaQuery(theme.breakpoints.up("sm"));

  const handleOpen = () => setOpen(true);

  useEffect(() => {
    getallCoffeeDetails();
  }, []);

  const convertText = (text) => {
    const temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = text;
    const decode =
      temporaryElement.textContent || temporaryElement.innerText || "";
    return decode;
  };

  const getAllCartItems = async (id) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${id}
    `;

    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const data = response.data;

      getCartCount(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getallCoffeeDetails = async () => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/products/${sku}
    `;
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const data = response.data;
      setCoffeeData(data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const createCart = async () => {
    setCartLoading(true);
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts
    `;
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios({
        method: "post",
        url,
        headers,
      });
      const data = response.data;
      localStorage.setItem("cartId", data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setCartLoading(false);
    }
  };

  const storeItem = async (id, qty, sku) => {
    let url =
      config.placeholderApiUrl +
      `/${storeCode}/V1/guest-carts/${id}/items
    `;
    setCartLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let value = {
        cartItem: {
          quote_id: id,
          product_type: "simple",
          sku: sku,
          qty: qty,
        },
      };

      const response = await axios({
        method: "post",
        url,
        value,
        headers,
        data: value,
      });

      setShowToaser({
        show: true,
        qt: qty,
      });
      getAllCartItems(id);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setCartLoading(false);
    }
  };

  const AddToCart = async (qty) => {
    let ID = localStorage.getItem("cartId");
    if (ID) {
      await storeItem(ID, qty, sku);
    } else {
      await createCart();
      ID = localStorage.getItem("cartId");
      await storeItem(ID, qty, sku);
    }
  };

  const getAccordion = () => {
    return (
      <Stack
        mt={tabletView ? 4 : 0}
        sx={{
          background: "white",
          marginTop: {
            xs: "0px",
            sm: "30px",
            md: "40px",
          },
          width: "100%",
        }}
      >
        <Stack
          sx={{
            padding: {
              xs: "0px",
              sm: "40px 100px",
              md: "50px 100px",
            },
          }}
        >
          <StyledAccordion
            defaultExpanded={tabletView ? true : false}
            disableGutters={true}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={
                tabletView ? (
                  <></>
                ) : (
                  <img src={`${baseImageUrl}/chevronDown.svg`} />
                )
              }
            >
              <TypographyBodyBase
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Descrizione tostatura
              </TypographyBodyBase>
            </AccordionSummary>
            <StyledAccordionDetails>
              <TypographyBodyRegular
                textAlign="start"
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: convertText(
                      coffeeData?.custom_attributes.find(
                        (attr) => attr.attribute_code === "description"
                      )?.value || ""
                    ),
                  }}
                />
              </TypographyBodyRegular>
            </StyledAccordionDetails>
          </StyledAccordion>
          <Divider />
          <StyledAccordion
            defaultExpanded={tabletView ? true : false}
            disableGutters={true}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={
                tabletView ? (
                  <></>
                ) : (
                  <img src={`${baseImageUrl}/chevronDown.svg`} />
                )
              }
            >
              <TypographyBodyBase
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Profilo aromatico
              </TypographyBodyBase>
            </AccordionSummary>
            <StyledAccordionDetails>
              <TypographyBodyRegular
                textAlign="start"
                dangerouslySetInnerHTML={{
                  __html: coffeeData?.custom_attributes.find(
                    (attr) => attr.attribute_code === "aromatic_profile"
                  )?.value,
                }}
              ></TypographyBodyRegular>
            </StyledAccordionDetails>
          </StyledAccordion>
          <Divider />
          <StyledAccordion
            defaultExpanded={tabletView ? true : false}
            disableGutters={true}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={
                tabletView ? (
                  <></>
                ) : (
                  <img src={`${baseImageUrl}/chevronDown.svg`} />
                )
              }
            >
              <TypographyBodyBase
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Ingredienti e allergeni
              </TypographyBodyBase>
            </AccordionSummary>
            <StyledAccordionDetails>
              <TypographyBodyRegular
                textAlign="start"
                dangerouslySetInnerHTML={{
                  __html: coffeeData?.custom_attributes.find(
                    (attr) =>
                      attr.attribute_code === "ingredients_and_allergens"
                  )?.value,
                }}
              ></TypographyBodyRegular>
            </StyledAccordionDetails>
          </StyledAccordion>
        </Stack>
      </Stack>
    );
  };

  const getInfoCard = (tabletView) => {
    return (
      <>
        <Card
          sx={{
            maxWidth: {
              xs: "100%",
              sm: "360px",
              md: "420px",
            },
            padding: {
              xs: "16px 16px 8px 16px",
              sm: "16px",
              md: "16px",
            },
          }}
        >
          <Stack
            direction="column"
            flex="flex"
            alignItems="center"
            justifyContent="center"
          >
            <TypographyLabelSmall
              sx={{
                fontSize: "12px",
                color: themeColors.common.white,
                fontWeight: 700,
                textAlign: "center",
                verticalAlign: "middle",
                background: themeColors.text.primary,
                width: "fit-content",
                padding: "4px 8px",
              }}
            >
              {coffeeData?.extension_attributes.category_name}
            </TypographyLabelSmall>

            {tabletView && (
              <Box
                component={"img"}
                src={coffeeData?.extension_attributes.product_image}
                width="100%"
                height="auto"
              />
            )}
            <H4
              sx={{
                marginTop: "16px",
              }}
            >
              {coffeeData?.name}
            </H4>

            <TypographyBodyBase
              sx={{
                color: "#626971",
              }}
              dangerouslySetInnerHTML={{
                __html: coffeeData?.custom_attributes.find(
                  (attr) => attr.attribute_code === "short_description"
                )?.value,
              }}
            ></TypographyBodyBase>

            <IntensityMaterial mt={2} coffeeData={coffeeData} />
            <Typography
              my={3}
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                color: "#3D8705",
                fontFamily: themeFonts.bold,
                lineHeight: "31.2px",
              }}
            >
              €{coffeeData?.price.toFixed(2)}
            </Typography>
            <StatisticsPanel coffeeData={coffeeData} />
            {coffeeData?.extension_attributes.stock_item.is_in_stock ? (
              <Button
                sx={{
                  margin: "32px 0px 16px 0px",
                }}
                variant="contained"
                onClick={handleOpen}
              >
                {cartLoading ? (
                  <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
                ) : (
                  "Aggiungi"
                )}
              </Button>
            ) : (
              <Typography
                sx={{ margin: "20px", color: themeColors.common.error }}
              >
                Quantità esaurita
              </Typography>
            )}
            {tabletView && getAccordion()}
          </Stack>
        </Card>
      </>
    );
  };

  const openNotification = () => {
    const key = showToaster.show;
    if (showToaster.show) {
      api.success({
        description: (
          <Typography
            fontFamily={themeFonts.regular}
            fontSize={"16px"}
            fontWeight={700}
            lineHeight={"17px"}
            letterSpacing={"1px"}
            color={themeColors.common.white}
            textAlign={"left"}
            marginLeft={"16px"}
          >
            Hai aggiunto {showToaster.qt} capsule Roma al tuo carrello
          </Typography>
        ),
        placement: "top",
        key,
        icon: (
          <Box
            sx={{ width: "40px", height: "40px" }}
            component={"img"}
            alt={"check Image"}
            src={`${baseImageUrl}/notificationCheck.png`}
          />
        ),
        closeIcon: null,
        className: "notification",
        style: {
          width: "343px",
          padding: "8px 16px 16px 16px",
          gap: "16px",
          background: "#12A244",
          borderRadius: "4px",
        },
      });
    }
  };

  return (
    <>
      {loading && <ProgressIndicator />}
      {openNotification()}
      {contextHolder}
      <QuantityModal
        stockQuantity={coffeeData?.extension_attributes.stock_item.qty}
        open={open}
        setOpen={setOpen}
        onSelected={(selectedQuantity) => {
          AddToCart(selectedQuantity);
        }}
      />
      <Stack direction="column" bgcolor={themeColors.common.pillWhite}>
        <Stack direction="column" px={2}>
          <Stack
            onClick={() => navigate(-1)}
            direction="row"
            gap={1}
            py={3}
            alignItems="center"
          >
            <Box
              component={"img"}
              width="16px"
              height="10px"
              src={`${baseImageUrl}/arrowLeftBlack.svg`}
            />
            <TypographyLabelLargeMedium
              sx={{
                color: themeColors.text.primary,
              }}
            >
              Torna al catalogo
            </TypographyLabelLargeMedium>
          </Stack>
        </Stack>
        {tabletView ? (
          <>
            <Stack direction="column" pb={2} px={2}>
              <Stack
                container
                direction="row"
                gap={2}
                justifyContent="space-evenly"
                alignItems="center"
                sx={{
                  maxWidth: {
                    sm: "800px",
                    md: "1280px",
                  },
                }}
              >
                <img
                  src={`${baseImageUrl}/mock-image-coffee.svg`}
                  width="50%"
                  height="auto"
                />
                {getInfoCard(false)}
              </Stack>
            </Stack>

            {getAccordion()}
          </>
        ) : (
          <>
            <Stack direction="column" pb={2} px={2}>
              {getInfoCard(true)}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default CoffeeDetails;
