import { Box, CircularProgress } from "@mui/material";
import { themeColors } from "../Constant/themeColor";

const ProgressIndicator = (props) => {
  return (
    <Box
      sx={{
        position: "absolute",

        backgroundColor: themeColors.text.primary,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress sx={{ color: themeColors.maincolor.tertiary }} />
    </Box>
  );
};

export default ProgressIndicator;
