import { Typography, styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";

export const StyleBodySmall = {
  fontWeight: 400,
  fontSize: '14px',
  fontFamily: themeFonts.regular,
  lineHeight: '18.2px',
  letterSpacing: '1px'
};

const TypographyBodySmall = styled(Typography)(({ theme, color }) => (StyleBodySmall));

export default TypographyBodySmall;