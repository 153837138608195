import { Typography, styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";

const TypographyLabelBase = styled(Typography)(({ theme, color }) => ({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '15.6px',
  letterSpacing: '1px',
  fontFamily: themeFonts.medium
}));

export default TypographyLabelBase;