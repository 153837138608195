import React, { useEffect } from "react";

const ThreeDSecureForm = ({
  authenticationToken,
  paymentData,
  paymentMethodType,
  subtype,
  token,
  type,
}) => {
  
    useEffect(() => {
    document.getElementById("3d-secure-form").submit();
  }, []);

  console.log("ThreeDSecureForm");

  return (
    <form method="post" action="https://checkout-test.adyen.com/v70/payments/details" id="3d-secure-form">
      <input
        type="hidden"
        name="authenticationToken"
        value={authenticationToken}
      />
      <input type="hidden" name="paymentData" value={paymentData} />
      <input type="hidden" name="paymentMethodType" value={paymentMethodType} />
      <input type="hidden" name="subtype" value={subtype} />
      <input type="hidden" name="token" value={token} />
      <input type="hidden" name="type" value={type} />
      <input type="hidden" name="returnUrl" value="your-callback-url" />
      <input type="submit" value="Proceed to 3D Secure Authentication" />
    </form>
  );
};

export default ThreeDSecureForm;
