import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { useEffect, useState } from "react";
import { themeColors } from "../../Constant/themeColor";
import { Alert, Box, Button, CircularProgress, Snackbar } from "@mui/material";
import "../../assets/Css/mainOriginalList.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { accessToken } from "../../Constant/token";
import config from "../../config";
import ThreeDSecureForm from "./threeDSPaymentMethod";

const AdyenCreditPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const storeCode = searchParams.get("store");
  const cartId = localStorage.getItem("cartId");
  console.log("storeCode", storeCode);
  console.log("cartId", cartId);

  // useEffect(() => {

  // }, []);

  const generateComponent = async () => {
    let url =
      config.placeholderApiUrl +
      `/V1/adyen/${cartId}/generate-component
      
    `;
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const values = {
        return_url: `http://localhost:3000/thankyou?store=${storeCode}`,
      };
      const response = await axios({
        method: "post",
        url,
        headers,
        data: values,
      });

      console.log("response", response);
      // window.open(url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: "20px 15px 20px 15px",
        }}
        component={"div"}
        className="adyen-payment-form"
      >
        <Button
          variant="contained"
          className="table-btn"
          onClick={() => {
            generateComponent();
          }}
          sx={{
            boxShadow: "none",
            padding: loading ? "13px 20px 11px 20px" : "17px 24px 15px 24px",
            backgroundColor: themeColors.maincolor.tertiary,
            "&:focus": {
              backgroundColor: themeColors.maincolor.tertiary,
            },
            "&:hover": {
              backgroundColor: themeColors.maincolor.tertiary,
            },
            "&:active": {
              backgroundColor: themeColors.maincolor.tertiary,
            },
          }}
        >
          {loading ? (
            <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
          ) : (
            "Pay with 3DS"
          )}
        </Button>
      </Box>
    </>
  );
};

export default AdyenCreditPayment;
