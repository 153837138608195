import React from "react";

import { Grid, Stack, Typography, styled } from "@mui/material";
import TypographyLabelLarge from "../../components/TypographyLabelLarge";
import TypographyLabelSmall from "../../components/TypographyLabelSmall";
import TypographyBodySmall from "../../components/TypographyBodySmall";

import { themeFonts } from "../../Constant/themeFont";
import { baseImageUrl } from "../../Constant/constData";

const Links = styled(TypographyLabelLarge)(({ theme, color }) => ({
  color: "white",
  fontFamily: themeFonts.medium,
}));

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: "black",
        alignItems: {
          lg: "center",
          xl: "center",
        },
        justifyContent: {
          lg: "center",
          xl: "center",
        },
      }}
    >
      <Stack
        sx={{
          display: "grid",
          gridAutoRows: "minmax(10px, auto)",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
            md: "repeat(4, 1fr)",
            lg: "repeat(4, 1fr)",
            xl: "repeat(4, 1fr)",
          },
          width: "100%",
          alignItems: {
            xs: "center",
            sm: "center",
            md: "start",
            lg: "start",
            xl: "start",
          },
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
            xl: "center",
          },
          padding: {
            xs: "0px",
            sm: "0px",
            md: "40px 0px 56px",
            lg: "40px 0px 56px",
            xl: "40px 0px 56px",
          },
          maxWidth: {
            md: "1130px",
            lg: "1130px",
            xl: "1130px",
          },
        }}
      >
        <Stack
          sx={{
            alignItems: {
              xs: "center",
              sm: "start",
              md: "start",
              lg: "start",
              xl: "start",
            },
            justifyContent: {
              xs: "center",
              sm: "start",
              md: "start",
              lg: "start",
              xl: "start",
            },
            padding: {
              xs: "32px 0px",
              sm: "0px 0px",
              md: "0px",
            },
            margin: {
              xs: "0px 24px",
              sm: "40px 16px 20px",
              md: "0px 16px",
              lg: "0px 16px",
              xl: "0px 16px",
            },
          }}
        >
          <img src={`${baseImageUrl}/logo-white.svg`} />
        </Stack>
        <Grid
          container
          gap={1}
          sx={{
            flexDirection: { xs: "column", sm: "row", md: "column" },
            gap: {
              xs: "8px",
              sm: "8px",
              md: "8px",
              lg: "8px",
              xl: "8px",
            },
            margin: {
              xs: "0px",
              sm: "20px 16px",
              md: "0px 16px",
              lg: "0px 16px",
              xl: "0px 16px",
            },
          }}
        >
          <Links>PRIVACY</Links>
          <Links>CONDIZIONI DI VENDITA</Links>
          <Links>NOTE LEGALI</Links>
          <Links>FAQ</Links>
        </Grid>
        <Stack
          gap={1}
          sx={{
            alignItems: {
              xs: "center",
              sm: "start",
              md: "start",
              lg: "start",
              xl: "start",
            },
            justifyContent: {
              xs: "center",
              sm: "start",
              md: "start",
              lg: "start",
              xl: "start",
            },
            margin: {
              xs: "32px 0px 0px 0px",
              sm: "20px 16px",
              md: "0px",
            },
          }}
        >
          {/* <TypographyBodySmall
            sx={{
              color: "white",
            }}
          >
            Follow Nespresso on
          </TypographyBodySmall> */}

          <Typography
            fontFamily={themeFonts.regular}
            fontSize={"14px"}
            fontWeight={700}
            lineHeight={"18.2px"}
            letterSpacing={"1px"}
            sx={{
              color: "white",
            }}
          >
            Follow Nespresso on
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            gap={2.2}
            justifyContent="center"
          >
            <img src={`${baseImageUrl}/ic-soc-facebook-f.svg`} />
            <img src={`${baseImageUrl}/ic-soc-instagram.svg`} />
            <img src={`${baseImageUrl}/ic-soc-youtube.svg`} />
            <img src={`${baseImageUrl}/ic-soc-twitter.svg`} />
            <img src={`${baseImageUrl}/ic-soc-pinterest.svg`} />
          </Stack>
        </Stack>
        <Stack
          gap={1}
          sx={{
            alignItems: {
              xs: "center",
              sm: "start",
              md: "start",
              lg: "start",
              xl: "start",
            },
            justifyContent: {
              xs: "center",
              sm: "start",
              md: "start",
              lg: "start",
              xl: "start",
            },
            margin: {
              xs: "24px 16px",
              sm: "20px 16px 40px",
              md: "0px",
            },
            display: {
              xs: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          }}
        >
          <TypographyBodySmall
            sx={{
              color: "white",
            }}
          >
            Puoi pagare con
          </TypographyBodySmall>
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            justifyContent="center"
          >
            <img src={`${baseImageUrl}/master.svg`} />
            <img src={`${baseImageUrl}/visa.svg`} />
            <img src={`${baseImageUrl}/amex.svg`} />
            <img src={`${baseImageUrl}/applepay-white.svg`} />
            <img src={`${baseImageUrl}/samsungpay.png`} />
            <img src={`${baseImageUrl}/gpay.png`} />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          gap={1}
          my={3}
          sx={{
            alignItems: {
              xs: "center",
              sm: "start",
              md: "start",
              lg: "start",
              xl: "start",
            },
            justifyContent: {
              xs: "center",
              sm: "start",
              md: "start",
              lg: "start",
              xl: "start",
            },
            margin: {
              xs: "24px 0px",
              sm: "0px 16px",
              md: "0px 16px",
            },
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
          onClick={handleScrollToTop}
        >
          <img src={`${baseImageUrl}/ic-arrow-up-white.svg`} />
          <TypographyLabelLarge
            sx={{
              color: "white",
            }}
          >
            Torna su
          </TypographyLabelLarge>
        </Stack>
      </Stack>
      <Stack
        width="100%"
        height="25px"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        sx={{
          color: "white",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        }}
      >
        <TypographyLabelSmall
          sx={{
            textTransform: "none",
          }}
        >
          Nestlé Nespresso S.A. 2023
        </TypographyLabelSmall>
      </Stack>
    </Stack>
  );
};

export default Footer;
