import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import "../../assets/Css/mainOriginalList.css";
import "../../assets/Css/mainOriginalListRes.css";
import { themeFonts } from "../../Constant/themeFont";
import { themeColors } from "../../Constant/themeColor";
import config from "../../config";
import { accessToken } from "../../Constant/token";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressIndicator from "../../components/ProgressIndicator";
import { baseImageUrl } from "../../Constant/constData";

const OriginalList = ({ categoryData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState(categoryData);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeCode = searchParams.get("store");

  useEffect(() => {
    if (!categoryData) {
      getAllProduct();
    }
  }, []);

  const getAllProduct = async () => {
    setLoading(true);

    let url =
      config.placeholderApiUrl + `/${storeCode}/V1/categories/product/list/`;
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios({
        method: "get",
        url,
        headers,
      });
      const { items } = response.data;
      setProductList(items);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ProgressIndicator />}
      <Box
        sx={{
          paddingTop: "32px",
          paddingBottom: "32px",
        }}
      >
        <Container maxWidth="md">
          
          {productList?.map((parent, i) => {
            return (
              <>
                {parent.child_category?.map((element, index) => {
                  return (
                    <>
                      <Box component={"div"} key={index}>
                        <Grid container>
                          <Box
                            sx={{
                              paddingTop: i || index > 0 ? "32px" : "0px",
                              paddingBottom: "16px",
                            }}
                          >
                            <Typography
                              variant="h4"
                              fontFamily={themeFonts.bold}
                              fontSize={"20px"}
                              fontWeight={700}
                              letterSpacing={"1px"}
                              lineHeight={"24px"}
                              sx={{
                                textTransform: "uppercase",
                              }}
                            >
                              {element.name}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid container className="maincart" spacing={1}>
                          {element.products.map((ele) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                lg={3}
                                key={ele.id}
                              >
                                <Box className="inner-content">
                                  <Grid container>
                                    <Grid item xs={3} sm={3} md={12} lg={12}>
                                      <Box className="card-img">
                                        <Box
                                          component={"img"}
                                          src={ele.image}
                                          alt="Coconut-img"
                                          className="coconut-img"
                                        />
                                      </Box>
                                    </Grid>

                                    <Grid item xs={9} sm={9} md={12} lg={12}>
                                      <Box
                                        component={"div"}
                                        className="card-text"
                                      >
                                        <Typography
                                          fontFamily={themeFonts.medium}
                                          lineHeight={"19px"}
                                          variant="h6"
                                          letterSpacing={"1px"}
                                          fontWeight={"700"}
                                          fontSize={"16px"}
                                          className="card-text-h6"
                                          textTransform={"uppercase"}
                                        >
                                          {ele.name}
                                        </Typography>
                                      </Box>

                                      {ele.intensity > 0 && (
                                        <Box
                                          component={"div"}
                                          sx={{
                                            margin: "4px 0px 0px 10px",
                                            padding: "5px 5px 5px 0px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            fontFamily={themeFonts.medium}
                                            variant="subtitle2"
                                            fontSize={"14px"}
                                            fontWeight={500}
                                            lineHeight={"18.2px"}
                                            className="price"
                                            letterSpacing={"1px"}
                                            color={themeColors.common.brown}
                                          >
                                            Intensità{" "}
                                            {Array(ele.intensity)
                                              .fill(null)
                                              .map((_, index) => {
                                                return (
                                                  <Box
                                                    marginRight={"2px"}
                                                    key={index + "dark"}
                                                    component={"img"}
                                                    src={`${baseImageUrl}/Intensity_dark.png`}
                                                  />
                                                );
                                              })}{" "}
                                            {ele.intensity}{" "}
                                            {Array(14 - ele.intensity)
                                              .fill(null)
                                              .map((_, index) => {
                                                return (
                                                  <Box
                                                    marginLeft={"2px"}
                                                    key={index + "light"}
                                                    component={"img"}
                                                    src={`${baseImageUrl}/Intensity_light.png`}
                                                  />
                                                );
                                              })}
                                          </Typography>
                                        </Box>
                                      )}

                                      <Box className="img-icon">
                                        <Typography
                                          fontFamily={themeFonts.medium}
                                          variant="subtitle2"
                                          fontSize={"18px"}
                                          fontWeight={700}
                                          lineHeight={"24px"}
                                          className="price"
                                          letterSpacing={"1px"}
                                          textTransform={"uppercase"}
                                        >
                                          €{Number(ele.price).toFixed(2)}
                                        </Typography>
                                        <Box
                                          component={"img"}
                                          onClick={() =>
                                            navigate(
                                              `/coffeeDetails/${ele.sku}?store=${storeCode}`
                                            )
                                          }
                                          sx={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                          src={`${baseImageUrl}/Plus.png`}
                                          alt="plus-icon"
                                          className="plus-img"
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    </>
                  );
                })}
              </>
            );
          })}
        </Container>
      </Box>
    </>
  );
};

export default OriginalList;
