import { Stack, styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import { themeColors } from "../Constant/themeColor";

import TypographyLabelLargeMedium from "./TypographyLabelLargeMedium";

const IntensityItem = styled(Box)(({ theme, color }) => ({
  height: "12px",
  width: "3px",
  backgroundColor: color,
}));

const IntensityMaterial = (props) => {
  const intensity = props.coffeeData?.custom_attributes.find(
    (attr) => attr.attribute_code === "intensity"
  )?.value;

  const level = Number(intensity);

  return (
    <Stack
      direction="row"
      flex="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <TypographyLabelLargeMedium
        sx={{
          color: themeColors.common.brown,
          height: "14px",
        }}
      >
        Intensità
      </TypographyLabelLargeMedium>
      <Stack direction="row" pl={1} gap="2px" height="12px">
        {[...Array(14)].map((item, index) => {
          const color =
            index < level
              ? themeColors.common.earthDark
              : themeColors.common.earth;
          if (index === level) {
            return (
              <>
                <TypographyLabelLargeMedium
                  mx={0.5}
                  sx={{
                    color: themeColors.common.brown,
                  }}
                >
                  {level}
                </TypographyLabelLargeMedium>
                <IntensityItem color={color} />
              </>
            );
          } else {
            return <IntensityItem color={color} />;
          }
        })}
      </Stack>
    </Stack>
  );
};

export default IntensityMaterial;
