import { Checkbox as BaseCheckbox, Stack, Typography } from "@mui/material";
import TypographyBodySmall from "./TypographyBodySmall";
import { baseImageUrl } from "../Constant/constData";

const Checkbox = (props) => {
  const { label, onChange } = props;
  return (
    <Stack direction="row" gap={1} my={2}>
      <BaseCheckbox
        sx={{
          width: 16,
          height: 16,
        }}
        size="small"
        icon={<img src={`${baseImageUrl}/ic-checkbox-unchecked.svg`} />}
        checkedIcon={<img src={`${baseImageUrl}/ic-checkbox-checked.svg`} />}
        onChange={onChange}
      />
      <TypographyBodySmall
        textAlign="start"
        sx={{
          whiteSpace: "pre-line",
        }}
      >
        {label}
      </TypographyBodySmall>
    </Stack>
  );
};

export default Checkbox;
