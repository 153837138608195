import { Typography, styled } from "@mui/material";
import { themeFonts } from "../Constant/themeFont";
import { themeColors } from "../Constant/themeColor";

const TypographyBodyBase = styled(Typography)(({ theme, color }) => ({
  fontWeight: 400,
  fontSize: '16px',
  fontFamily: themeFonts.light,
  lineHeight: '20.8px',
  letterSpacing: '1px'
}));

export default TypographyBodyBase;