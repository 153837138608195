import { Stack, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import TypographyLabelBase from "./TypographyLabelBase";
import TypographyLabelBaseBold from "./TypographyLabelBaseBold";
import { themeColors } from "../Constant/themeColor";

const StatisticsItem = styled(Box)(({ theme, color }) => ({
  backgroundColor: color,
}));

const Statistics = ({ label, filled = 2 }) => {
  return (
    <Stack
      direction="row"
      flex="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
    >
      <Box
        direction="row"
        flex="flex"
        alignItems="center"
        justifyContent="start"
      >
        <TypographyLabelBaseBold
          sx={{
            textTransform: "uppercase",
          }}
        >
          {label}
        </TypographyLabelBaseBold>
      </Box>
      <Stack
        direction="row"
        display="flex"
        gap={0.3}
        alignItems="center"
        justifyContent="end"
      >
        {[...Array(5)].map((item, index) => {
          const color =
            index < filled
              ? themeColors.common.brown
              : themeColors.common.surfaceGray;
          return (
            <StatisticsItem
              key={index}
              color={color}
              sx={{
                width: {
                  xs: "45px",
                  sm: "38px",
                  md: "54px",
                },
                height: "3px",
              }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

const StatisticsPanel = ({ coffeeData }) => {
  const amarezza = coffeeData?.custom_attributes.find(
    (attr) => attr.attribute_code === "amarezza"
  )?.value;

  const acidita = coffeeData?.custom_attributes.find(
    (attr) => attr.attribute_code === "acidita"
  )?.value;

  const tostatura = coffeeData?.custom_attributes.find(
    (attr) => attr.attribute_code === "tostatura"
  )?.value;

  const corpo = coffeeData?.custom_attributes.find(
    (attr) => attr.attribute_code === "corpo"
  )?.value;

  // console.log("coffeeData", coffeeData)

  return (
    <Stack direction="column" gap={0.5}>
      <Statistics label="AMAREZZA" filled={amarezza} />
      <Statistics label="ACIDITÀ" filled={acidita} />
      <Statistics label="TOSTATURA" filled={tostatura} />
      <Statistics label="CORPO" filled={corpo} />
    </Stack>
  );
};

export default StatisticsPanel;
